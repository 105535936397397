import { useLocation, useNavigate, useParams } from "react-router-dom";

import { cn } from "src/lib/utils";
import { MENUS } from "src/constant";

export const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { slug } = useParams();

  // functions
  const handleNavigate = (path: string) => {
    if (!slug) {
      navigate(path);
    } else {
      navigate(`${path}/${slug}`);
    }
  };

  return (
    <div className="h-[72px] border-b border-slate-300 px-4 flex items-center">
      <ul className="flex items-center gap-4 border border-slate-300 py-1 px-[5px] rounded-[6px]">
        {MENUS.map((menu) => {
          return (
            <li
              key={`menu-${menu.id}`}
              className={cn(
                "px-3 py-[6px] rounded text-black text-sm font-medium cursor-pointer transition-all hover:bg-slate-200",
                location.pathname.includes(menu.path) ? "bg-slate-200" : "",
              )}
              onClick={() => handleNavigate(menu.path)}
            >
              {menu.title}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
