import { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import { cn } from "src/lib/utils";
import { StrategyMenu } from "src/models/step-type";
import { getSetUpTypeList } from "src/services/setup-type";
import {
  ColumnChartIcon,
  AccountSettingIcon,
  PaymentIcon,
  LogOutIcon,
} from "src/icons";

interface Props {
  className?: string;
}

const ASIDE_BLOCKS = [
  {
    id: 2,
    title: "Your Account",
    menus: [
      // {
      //   id: 1,
      //   title: "Account Settings",
      //   value: "account-settings",
      //   path: "/account-settings",
      //   icon: AccountSettingIcon,
      //   isType: "page",
      // },
      // {
      //   id: 2,
      //   title: "Payment",
      //   value: "payment",
      //   path: "/payment",
      //   icon: PaymentIcon,
      //   isType: "page",
      // },
      {
        id: 3,
        title: "Log out",
        value: "log_out",
        icon: LogOutIcon,
        isType: "page",
      },
    ],
  },
];
const NORMAL_MENU_LIST = ["/account-settings", "/payment"];

const Sidebar: React.FC<Props> = ({ className }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { slug } = useParams();

  // state
  const [activeMenu, setActiveMenu] = useState<string | null>(null);
  const [strategyMenuList, setStrategyMenuList] = useState<StrategyMenu[]>([]);

  // functions
  const handleNavigate = (menu: {
    id: number;
    title: string;
    value: string;
    icon: () => JSX.Element;
    isType: string;
    path?: string;
  }) => {
    if (menu?.value === "log_out") {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      navigate("/login");
    } else if (menu?.path) {
      navigate(menu.path);
    }
  };

  const handleGetSetUpTypeList = async () => {
    const res = await getSetUpTypeList();

    if (res) {
      setStrategyMenuList(res);
    }
  };

  const handleSelectStrategy = (strategy: StrategyMenu) => {
    const locationArray = location.pathname.split("/");

    if (locationArray?.length === 3) {
      locationArray[locationArray.length - 1] = strategy.id;
    } else {
      locationArray.push(strategy.id);
    }

    const newPath = locationArray.join("/");
    navigate(newPath);
  };

  // effects
  useEffect(() => {
    const currentPath = location.pathname;

    if (NORMAL_MENU_LIST.includes(currentPath)) {
      setActiveMenu(currentPath);
    } else {
      const pathList = currentPath.split("/");
      const strategyId = pathList[pathList.length - 1];

      if (strategyId) {
        setActiveMenu(strategyId);
      }
    }
  }, [location]);

  useEffect(() => {
    handleGetSetUpTypeList();
  }, []);

  return (
    <aside
      className={cn(
        "min-w-[240px] h-screen border-r border-slate-300",
        className ? className : "",
      )}
    >
      <div className="h-[72px] border-b border-slate-300 px-2">
        <h1 className="text-slate-700 text-xl font-semibold leading-[72px] cursor-pointer">
          VSA Trading
        </h1>
      </div>

      <div className="border-b border-slate-300 px-4 py-3">
        <div className="px-2 py-3">
          <h2 className="text-slate-700 text-sm font-semibold leading-5">
            Strategy
          </h2>
        </div>

        <ul className="p-1 space-y-1">
          {strategyMenuList.map((strategy) => (
            <li
              key={`aside-menu-${strategy.id}`}
              className={cn(
                "px-2 py-2 cursor-pointer rounded-[6px] text-slate-700 hover:bg-slate-600 hover:text-slate-50 transition-all",
                activeMenu === strategy.id ? "bg-slate-600 text-slate-50" : "",
              )}
              onClick={() => handleSelectStrategy(strategy)}
            >
              <div className="flex items-center gap-2 text-inherit">
                <ColumnChartIcon />
                <p className="text-sm font-medium text-inherit">
                  {strategy.name}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {ASIDE_BLOCKS.map((block) => {
        return (
          <div
            key={`aside-block-${block.id}`}
            className={cn(
              "border-slate-300 px-4 py-3 border-b-0",
              block.id === ASIDE_BLOCKS.length ? "border-b-0" : "",
            )}
          >
            <div className="px-2 py-3">
              <h2 className="text-slate-700 text-sm font-semibold leading-5">
                {block.title}
              </h2>
            </div>
            <ul className="p-1 space-y-1">
              {block.menus.map((menu) => {
                return (
                  <li
                    key={`aside-menu-${menu.id}`}
                    className={cn(
                      "px-2 py-2 cursor-pointer rounded-[6px] text-slate-700 hover:bg-slate-600 hover:text-slate-50 transition-all",
                      // activeMenu === menu?.path
                      //   ? "bg-slate-600 text-slate-50"
                      //   : "",
                    )}
                    onClick={() => handleNavigate(menu)}
                  >
                    <div className="flex items-center gap-2 text-inherit">
                      <menu.icon />
                      <p className="text-sm font-medium text-inherit">
                        {menu.title}
                      </p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </aside>
  );
};

export default Sidebar;
