import { lazy } from "react";

import { Loader } from "src/components/common";

import MainLayout from "src/layouts/MainLayout";
import PaymentPage from "src/pages/payment";
import AccountSettingsPage from "src/pages/account-settings";
import AuthLayout from "src/layouts/AuthLayout";
import LoginPage from "src/pages/login";

const HomePage = Loader(lazy(() => import("src/pages/home")));
const PlayChartsPage = Loader(lazy(() => import("src/pages/play-charts")));
const ChartsHistoryPage = Loader(
  lazy(() => import("src/pages/charts-history")),
);
const LearnConceptsPage = Loader(
  lazy(() => import("src/pages/learn-concepts")),
);
const QuizTestPage = Loader(lazy(() => import("src/pages/quiz-test")));
const PlayChartsDetailPage = Loader(
  lazy(() => import("src/pages/play-charts/detail")),
);
const LearnConceptsDetailPage = Loader(
  lazy(() => import("src/pages/learn-concepts/detail")),
);
const QuizTestDetailPage = Loader(
  lazy(() => import("src/pages/quiz-test/detail")),
);
const ChartsHistoryDetailPage = Loader(
  lazy(() => import("src/pages/charts-history/detail")),
);

export const PUBLIC_ROUTES = [
  {
    id: "public-1",
    path: "/login",
    page: LoginPage,
    layout: AuthLayout,
  },
];

export const PRIVATE_ROUTES = [
  {
    id: "private-1",
    path: "/",
    page: HomePage,
    layout: MainLayout,
  },
  {
    id: "private-2",
    path: "/play-charts",
    page: PlayChartsPage,
    layout: MainLayout,
  },
  {
    id: "private-2",
    path: "/play-charts/:slug",
    page: PlayChartsDetailPage,
    layout: MainLayout,
  },
  {
    id: "private-3",
    path: "/charts-history",
    page: ChartsHistoryPage,
    layout: MainLayout,
  },
  {
    id: "private-4",
    path: "/learn-concepts",
    page: LearnConceptsPage,
    layout: MainLayout,
  },
  {
    id: "private-8",
    path: "/learn-concepts/:slug",
    page: LearnConceptsDetailPage,
    layout: MainLayout,
  },
  {
    id: "private-5",
    path: "/quiz-test",
    page: QuizTestPage,
    layout: MainLayout,
  },
  {
    id: "private-6",
    path: "/payment",
    page: PaymentPage,
    layout: MainLayout,
  },
  {
    id: "private-7",
    path: "/account-settings",
    page: AccountSettingsPage,
    layout: MainLayout,
  },
  {
    id: "private-9",
    path: "/quiz-test/:slug",
    page: QuizTestDetailPage,
    layout: MainLayout,
  },
  {
    id: "private-10",
    path: "/charts-history/:slug",
    page: ChartsHistoryDetailPage,
    layout: MainLayout,
  },
];
