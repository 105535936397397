import { StrategyMenu } from "src/models/step-type";

import api from "../api";

export const getSetUpTypeList = async (): Promise<StrategyMenu[]> => {
  try {
    const res = await api.get("/setup_type");
    return res.data?.data?.data || [];
  } catch (error) {
    return [];
  }
};
