import { z } from "zod";
import { Mail } from "lucide-react";
import { Loader2 } from "lucide-react";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";

import { login } from "src/services/auth";
import { Input } from "src/components/shadcn/Input";
import { Button } from "src/components/shadcn/Button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "src/components/shadcn/Card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/components/shadcn/Form";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "src/components/shadcn/Dialog";

const formSchema = z.object({
  email: z.string().email({
    message: "Please enter a valid email address.",
  }),
  password: z.string().min(1, {
    message: "Password is required.",
  }),
});

const LoginPage = () => {
  const navigate = useNavigate();

  // state
  const [isLoading, setIsLoading] = useState(false);
  const [sendEmailStatus, setSendEmailStatus] = useState<boolean>(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  // functions
  const handleLogin = async (email: string, password: string) => {
    setIsLoading(true);
    const res = await login(email, password);
    setIsLoading(false);

    if (res) {
      localStorage.setItem("access_token", res.access_token);
      localStorage.setItem("refresh_token", res.refresh_token);

      navigate("/play-charts");
    }
  };

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    handleLogin(data.email, data.password);
  };

  return (
    <div className="grow flex items-center justify-center gap-2">
      <img
        src="/images/main-img.png"
        alt="vsa trading"
        className="h-full w-auto"
      />
      <div className="flex-1">
        <Card className="max-w-[400px] mx-auto">
          <CardHeader className="pb-4">
            <CardTitle className="hidden">Login</CardTitle>
            <CardDescription>
              Learn trading with VSA Trading. Free membership. You only need a
              real email 😊
            </CardDescription>
          </CardHeader>
          <CardContent>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="w-full space-y-6"
              >
                <div className="space-y-4">
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem className="space-y-0">
                        <FormLabel className="text-xs font-medium">
                          Your Email
                        </FormLabel>
                        <FormControl className="mt-0">
                          <Input
                            placeholder="Enter your email"
                            {...field}
                            className="mt-0 w-full"
                          />
                        </FormControl>
                        <FormMessage className="text-xs" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="password"
                    render={({ field }) => (
                      <FormItem className="space-y-0">
                        <FormLabel className="text-xs font-medium">
                          Your Password
                        </FormLabel>
                        <FormControl className="mt-0">
                          <Input
                            type="password"
                            placeholder="Enter your password"
                            {...field}
                            className="mt-0 w-full"
                          />
                        </FormControl>
                        <FormMessage className="text-xs" />
                      </FormItem>
                    )}
                  />
                </div>
                <Button
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading && <Loader2 className="w-4 h-4 animate-spin" />}
                  <p>Login</p>
                </Button>
              </form>
            </Form>
          </CardContent>
        </Card>
      </div>

      <Dialog
        open={sendEmailStatus}
        onOpenChange={() => {
          setSendEmailStatus(false);
        }}
      >
        <DialogContent>
          <DialogHeader>
            <div className="flex items-center w-full justify-center">
              <Mail className="size-10" />
            </div>
            <DialogTitle className="text-center">
              Check your email for login link
            </DialogTitle>
            <DialogDescription className="text-center">
              We sent you an activation link to {form.getValues("email")}.
              Please check your email and click the link to activate your
              account.
            </DialogDescription>
          </DialogHeader>

          <div className="hidden">Content</div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LoginPage;
